import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "JavaScript Console – Life Saving Tricks",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:58:02.000Z",
  "url": "/2018/02/javascript-console-life-saving-tricks/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Are you still having those console.log lines to debug your code or to inform other devs for the use of your code?`}</p>
    <p>{`If so, read on`}{`…`}</p>
    <h6>{`Designed Log Message {#designedlogmessage}`}</h6>
    <pre><code parentName="pre" {...{}}>{`console.log('%cdesigned', 'css:rules; right:here');
`}</code></pre>
    <p>{`![designed console.log][1]`}</p>
    <h6>{`Different Types Of Log Messages {#differenttypesoflogmessages}`}</h6>
    <ul>
      <li parentName="ul">{`log: We all know this one`}</li>
      <li parentName="ul">{`Warn: eye-catching yellow exclamation message`}</li>
      <li parentName="ul">{`info: looks similar to `}{`“`}{`log`}{`”`}</li>
      <li parentName="ul">{`error: eye-catching red error message`}</li>
    </ul>
    <p>{`** warn & error will add a counter to the console counters`}</p>
    <pre><code parentName="pre" {...{}}>{`console.log('some log message');
console.info('some info message');
console.warn('some warning message');
console.error('some error message');
`}</code></pre>
    <p>{`![types of console logs][2]`}</p>
    <h6>{`Join Logs to a Group {#joinlogstoagroup}`}</h6>
    <ul>
      <li parentName="ul">{`group: start the group`}</li>
      <li parentName="ul">{`groupCollapse: same as `}{`“`}{`group`}{`”`}{` but collapsed by default`}</li>
      <li parentName="ul">{`groupEnd: close the above group
console.group('groupName');
console.log('some message');
console.log('another message');
//close the closes above group, ie: groupName
console.groupEnd();
`}</li>
    </ul>
    <p>{`![group console logs][3]`}{`![group console logs collapsed][4]`}</p>
    <h6>{`Track Time With Console {#tracktimewithconsole}`}</h6>
    <pre><code parentName="pre" {...{}}>{`console.time(eventName);
//some long process
console.timeEnd(eventName);
`}</code></pre>
    <p>{`![designed console.log][5]`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      